import { addDays, format } from 'date-fns';
import { DAILY_GAME_QUERY, DailyGameFields, getDailyGameSrc } from '@/lib/drupal/models/Games';
import { getZonedDate } from '@/lib/util/zonedTime';



export function formatDesktopArchiveDate (fields: DailyGameFields, short?: boolean) {
  const date = this.dailyFieldsToDate(fields);
  // May 2, 2024
  if (short) {
    return format(date, 'MMM d, yyyy');
  }
  // Sunday, May 2<br />2024
  return format(date, 'EEEE, MMM d') + '<br />' + format(date, 'yyyy');
}

export function formatMobileArchiveDate (fields: DailyGameFields, short?: boolean) {
  const date = this.dailyFieldsToDate(fields);
  // May 2, 2024
  if (short) {
    return format(date, 'MMM d, yyyy');
  }
  // Sun, May 2<br />2024
  return format(date, 'EEE, MMM d') + '<br />' + format(date, 'yyyy');
}

export function getArchiveItemHref (
  fields: DailyGameFields, 
  slug: string,
  isToday?: boolean
) {
  const rootSrc = '/gamelanding';

  if (isToday) {
    return rootSrc + `/${slug}`;
  }

  return getDailyGameSrc(
    rootSrc + `/${slug}?${DAILY_GAME_QUERY}`, 
    fields
  );
}

export function dailyFieldsToDateKey (fields: DailyGameFields): string {
  return `20${fields.year}-${fields.month.padStart(2, '0')}-${fields.day.padStart(2, '0')}`;
}

export function isToday (fields: DailyGameFields) {
  const today = getZonedDate();
  const date = this.dailyFieldsToDate(fields);
  return today.toLocaleDateString('en-US') === date.toLocaleDateString('en-US');
}

export function dateToDailyFields (date: Date) {
  return {
    day: date.getDate().toString().padStart(2, '0'),
    month: (date.getMonth() + 1).toString().padStart(2, '0'),
    year: date.getFullYear().toString().slice(-2),
  };
}

export function dailyFieldsToDate (fields: DailyGameFields) {
  const date = getZonedDate();
  date.setFullYear(parseInt('20' + fields.year));
  date.setMonth(parseInt(fields.month) - 1);
  date.setDate(parseInt(fields.day));

  return date;
}

export function isAfterTomorrow (fields: DailyGameFields) {
  const today = getZonedDate();
  const todayLocaleString = getZonedDate().toLocaleDateString('en-US');
  const tomorrowLocaleString = addDays(getZonedDate(), 1).toLocaleDateString('en-US');

  const itemDate = this.dailyFieldsToDate(fields);
  const itemLocaleString = itemDate.toLocaleDateString('en-US');

  return todayLocaleString !== itemLocaleString && tomorrowLocaleString !== itemLocaleString && itemDate > today;
}

export function getThumbnail (fields: DailyGameFields, contentUrl: string, kind: string = 'daily') {
  const month = fields.month.length === 1
    ? '0' + fields.month
    : fields.month;
  const year = fields.year;
  const day = fields.day.charAt(0) === '0' 
    ? fields.day.replace('0', '') 
    : fields.day;
  return `${contentUrl}/${month}_${year}/${kind}${day}.jpg`;
}